import React, { useState } from "react"
import Layout from "../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import HomestayVideo from "../video/Homestay.mp4"
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton"
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements"
import Logo from "../images/logo.png"
import { educationBanner } from "../images"
import Accordion from "../components/Accordions/WorkWithUsHomestayFAQ"
import LargeVideo from "../components/Video/LargeVideo"
import {
  EducationTestimony,
  InsertedTestimony,
} from "../components/Testimonials"
import { EducationPopup } from "../components/FormPopup"
import { CentreWrapper, RealButton } from "../components/ButtonElement"
import SEO from "../components/seo"
import { navigate } from "gatsby"

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ApplicationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: "CareersAdvice",
    title: "職業建議",
  },
  {
    to: "CourseApplications",
    title: "課程申請",
  },
  {
    to: "PrivateTuition",
    title: "私人補習",
  },
  {
    to: "Cultural",
    title: "文化和禮儀培訓",
  },
  {
    to: "HolidayCamps",
    title: "度假營",
  },
  {
    to: "Familiarisation",
    title: "熟悉之旅",
  },
  {
    to: "StudyTours",
    title: "遊學",
  },
  {
    to: "InternationalTeacherRecruitment",
    title: "國際教師招聘",
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br />
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function EducationServices({ data }) {
  const html = data.allWpPage.edges
  const Applications = ReactHtmlParser(html[0].node.content)
  const CareersService = ReactHtmlParser(html[1].node.content)
  const FamiliarisationTrips = ReactHtmlParser(html[2].node.content)
  const HolidayCamp = ReactHtmlParser(html[3].node.content)
  const InternationalTeacherRecruitment = ReactHtmlParser(html[4].node.content)
  const PrivateTuition = ReactHtmlParser(html[5].node.content)
  const StudyTours = ReactHtmlParser(html[6].node.content)
  const Header = ReactHtmlParser(html[7].node.content)
  const Cultural = ReactHtmlParser(html[8].node.content)
  const [formType, setFormType] = useState("")
  return (
    <div>
      <Layout
        sidebar={sidebar}
        pageTitle="教育服務"
        background={educationBanner}
        pageDescription="提供獲獎建議和服務，幫助他們獲得最好的英國教育"
      >
        <SEO
          title="Education Services | UK Education | Oxbridge | Careers | Career Advice"
          description="Our bespoke education careers service helps truly ambitious and determined students identify and fully prepare for their chosen career path."
        />
        <Section id="CareersAdvice">
          <FadeH1>職業建議</FadeH1>
          {CareersService.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
          <EducationPopup formType={formType} />
        </Section>
        <Section id="CourseApplications">
          <FadeH1>課程申請</FadeH1>
          {Applications.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="PrivateTuition">
          <FadeH1>私人補習</FadeH1>
          {PrivateTuition.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="Cultural">
          <FadeH1>文化和禮儀培訓</FadeH1>
          {Cultural.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="HolidayCamps">
          <FadeH1>度假營</FadeH1>
          {HolidayCamp.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="Familiarisation">
          <FadeH1>熟悉之旅</FadeH1>
          {FamiliarisationTrips.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <InsertedTestimony
            author="西班牙代理商"
            quote="這種旅行對我們很好地瞭解寄宿學校及其之間的差異非常有用。"
          />
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="StudyTours">
          <FadeH1>遊學；交流項目；學校結對計劃</FadeH1>
          {StudyTours.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="InternationalTeacherRecruitment">
          <FadeH1>國際教師招聘</FadeH1>
          {InternationalTeacherRecruitment.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
        </Section>
        <EducationTestimony />
      </Layout>
    </div>
  )
}

export const STUDYLINKS_EDUCATIONSERVICES = graphql`
  query studylinks_educationservices {
    allWpPage(
      filter: {
        title: {
          in: [
            "api-studylinks-simplified-education services-Private Tuition"
            "api-studylinks-simplified-education services-header"
            "api-studylinks-simplified-education services-Holiday Camps"
            "api-studylinks-simplified-education services-Applications"
            "api-studylinks-simplified-education services-Careers Services"
            "api-studylinks-simplified-education services-Familiarisation Trips"
            "api-studylinks-simplified-education services-Study Tours; Exchange Programmes; School Twinning Programmes"
            "api-studylinks-simplified-education services-International Teacher Recruitment"
            "api-studylinks-simplified-support services-Cultural & Etiquette Training"
          ]
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
